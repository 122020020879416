@import '../../../../scss/theme-bootstrap';

.basic-carousel-formatter {
  position: relative;
  width: 100%;
  .basic-carousel {
    margin-bottom: 5px;
  }
  .carousel-controls {
    li button {
      position: absolute;
      @include breakpoint($landscape-up) {
        margin-top: 2px;
        transform: scale(0.4);
      }
    }
    .slick-prev-button {
      order: 1;
      cursor: pointer;
    }
    .slick-dots {
      order: 2;
      width: unset;
      li {
        height: 7px;
        width: 7px;
        @include breakpoint($landscape-up) {
          height: 12px;
          width: 12px;
        }
      }
    }
    .slick-next-button {
      order: 3;
      cursor: pointer;
    }
  }
  &__counter {
    display: none;
    .counter-active & {
      display: block;
      text-align: $rdirection;
      margin: 0 5px;
    }
  }
  &__header {
    margin-#{$ldirection}: 10px;
    margin-bottom: 18px;
    @include breakpoint($landscape-up) {
      margin-bottom: 10px;
    }
  }
  .icon--chevron,
  .icon--chevron--left {
    width: 35px;
    height: 35px;
  }
  &--arrows-dots-white {
    .slick-prev,
    .slick-next {
      fill: $color-white;
    }
    .slick-dots {
      li.slick-active {
        button:before {
          background-color: $color-white;
          border: none;
        }
      }
    }
  }
  &--next-slide {
    .slick-list {
      @include swap_direction(padding, 0 36% 0 0);
      @include breakpoint($landscape-up) {
        padding: 0;
      }
    }
    .slick-slide {
      @include breakpoint($small-down) {
        margin: 0 7.5px;
      }
    }
  }
}
